import product1 from "./assets/products/MBB PERC MONOFACIAL MODULE 500W-500W MARQUE IFRISOL.png";
import product2 from "./assets/products/MBB PERC MONOFACIAL MODULE 395W MARQUE IFRISOL.png";
import product3 from "./assets/products/MBB PERC MONOFACIAL MODULE 335W MARQUE IFRISOL.png";
import product4 from "./assets/products/MBB PERC MONOFACIAL MODULE 270W MARQUE RESUN.png";
import product5 from "./assets/products/Climatiseur 12000BTU-ON OFF R22 MARQUE SUMSUNG.png";
import product6 from "./assets/products/Climatiseur 9000BTU-VERTU-ON OFF R22 MARQUE CARRIER.png";
import product7 from "./assets/products/Climatiseur 12000BTU- ON OFF R410 MARQUE AIRWELL.png";
import product8 from "./assets/products/Climatiseur 9000BTU-INVERTER R410 MARQUE AIRWELL.png";
export const PRODUCTS = [
  {
    id: 1,
    productName: "MBB PERC MONOFACIAL MODULE 500W-505W-510W",
    price:"",
    productImage: product1,
    category: "panneau photovoltaique",
    description: "Marque: IFRISOL\nHigh Performance Half-Cut Cells With White Backsheet Photovoltaic Modules\n12 Years Product Warranty on 91.95% of the Nominal Performance\n25 Years Linear Power Output Warranty on 84.80% of the Nominal Performance\nPrix: \nQté: 16"
  },
  {
    id: 2,
    productName: "MBB PERC MONOFACIAL MODULE 395W",
    price:"",
    productImage: product2,
    category: "panneau photovoltaique",
    description: "Marque: IFRISOL\nHigh Performance MonoPerc With White Backsheet Photovoltaic Modules\n12 Years Product Warranty on 91.95% of the Nominal Performance\n25 Years Linear Power Output Warranty on 84.80% of the Nominal Performance\nPrix: \nQté: 1"
  },
  {
    id: 3,
    productName: "MBB PERC MONOFACIAL MODULE 335W",
    price:"",
    productImage: product3,
    category: "panneau photovoltaique",
    description: "Marque: IFRISOL\nHigh Performance MonoPerc With White Backsheet Photovoltaic Modules\n12 Years Product Warranty on 91.95% of the Nominal Performance\n25 Years Linear Power Output Warranty on 84.80% of the Nominal Performance\nPrix: \nQté: 2"
  },
  {
    id: 4,
    productName: "MBB PERC MONOFACIAL MODULE 270W",
    price:"",
    productImage: product4,
    category: "panneau photovoltaique",
    description: "Marque: RESUN\nHigh Performance Poly\n12 Years Product Warranty on 91.95% of the Nominal Performance\n25 Years Linear Power Output Warranty on 84.80% of the Nominal Performance\nPrix: \nQté: 14"
  },
  {
    id: 5,
    productName: "Climatiseur 9000BTU-INVERTER R410",
    price: "", // Price not provided
    productImage: product5,
    category: "Climatisation",
    description: "Marque: AIRWELL\nQté: 3" // Description includes brand and quantity
  },
  {
    id: 6,
    productName: "Climatiseur 12000BTU-ON/OFF R410",
    price: "", // Price not provided
    productImage: product6,
    category: "Climatisation",
    description: "Marque: AIRWELL\nQté: 5" // Description includes brand and quantity
  },
  {
    id: 7,
    productName: "Climatiseur 9000BTU-VERTU-ON/OFF R22",
    price: "", // Price not provided
    productImage: product7,
    category: "Climatisation",
    description: "Marque: CARRIER\nQté: 1" // Description includes brand and quantity
  },
  {
    id: 8,
    productName: "Climatiseur 12000BTU-ON/OFF R22",
    price: "", // Price not provided
    productImage: product8,
    category: "Climatisation",
    description: "Marque: SAMSUNG\nQté: 1" // Description includes brand and quantity
  },
  
];
