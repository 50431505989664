import React, { useContext } from "react";
import { ShopContext } from "../../context/shop-context";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { PRODUCTS } from "../../products";
import "./contact.css";

export const Contact = () => {
  const { cartItems, removeFromCart, updateCartItemCount } = useContext(ShopContext);

  const removeProductFromCart = (itemId) => {
    updateCartItemCount(0, itemId);
    removeFromCart(itemId);
  };
  const handleQuantityChange = (itemId, quantity) => {
    updateCartItemCount(quantity, itemId);
  };


  const renderProductRows = () => {
    return Object.entries(cartItems || {}).map(([itemId, quantity]) => {
      const product = PRODUCTS.find((p) => p.id === Number(itemId));
      if (product && quantity > 0) {
        return (
          <tr key={itemId}>
            <td>
            <div> <div className="contact-image-container"> <img src={product.productImage} alt={product.productName} width='50px' height='50px'/> </div> {product.productName} </div>
              
            </td>
            <td>  
              <input
                 type="number"
                  min="1"
                 value={quantity}
                 onChange={(e) =>
                   handleQuantityChange(itemId, parseInt(e.target.value))
                 }
               /> 
              </td>
            <td>${product.price * quantity}</td>
            <td>
            <FontAwesomeIcon icon={faTrashAlt} onClick={() =>  removeProductFromCart(itemId)} style={{ color: 'red' }} />
            </td>
          </tr>
        );
      }
      return null;
    });
  };

  const getTotalPrice = () => {
    let totalPrice = 0;
    Object.entries(cartItems || {}).forEach(([itemId, quantity]) => {
      const product = PRODUCTS.find((p) => p.id === Number(itemId));
      if (product) {
        totalPrice += product.price * quantity;
      }
    });
    return totalPrice;
  };

 
  return (
    <div className="contact-form-container">
      <div className="card">
        <div className="card-header">
          <h2>Continuez-vos achats </h2>
        </div>
        <div className="card-body">
          <form action="https://usebasin.com/f/e28ca9286cbe" method="POST">
            <div className="form-group">
              <label htmlFor="my-shopping">Votre Panier:</label>
              <div className="cart-items">
                <table className="product-table">
                  <thead>
                    <tr>
                      <th>Produit</th>
                      <th>Quantité</th>
                      <th>Prix</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{renderProductRows()}</tbody>
                </table>
              </div>
            </div>
            <div className="form-group">
              <div className="total-price">Total: ${getTotalPrice()}</div>
            </div>
            <div className="group-container">
              <div className="input-container">
                <label htmlFor="nom">Nom:</label>
                <input
                  type="text"
                  id="nom"
                  name="nom"
                  required
                  placeholder="Exemple: Dupont"
                />
              </div>
              <div className="input-container">
                <label htmlFor="prenom">Prenom:</label>
                <input
                  type="text"
                  id="prenom"
                  name="prenom"
                  required
                  placeholder="Exemple: Jean"
                />
              </div>
            </div>
            <div className="separator"></div>
            <div className="group-container">
              <div className="input-container">
                <label htmlFor="adresse">Adresse:</label>
                <input
                  type="text"
                  id="adresse"
                  name="adresse"
                  required
                  placeholder="Exemple: 123 rue ..."
                />
              </div>
              <div className="input-container">
                <label htmlFor="ville">Ville:</label>
                <input
                  type="text"
                  id="ville"
                  name="ville"
                  required
                  placeholder="Exemple: Paris"
                />
              </div>
            </div>
            <div className="separator"></div>
            <div className="group-container">
              <div className="input-container">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  placeholder="Exemple: email@example.com"
                />
              </div>
              <div className="input-container">
                <label htmlFor="numero">Numéro de téléphone:</label>
                <input
                  type="tel"
                  id="numero"
                  name="numero"
                  required
                  placeholder="Exemple: 0123456789"
                />
              </div>
            </div>
           
            <div className="form-group">
              <button type="submit">Envoyer</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
