import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PRODUCTS } from "../../products";
import { Product } from "./product";
import "./shop.css";

// Import the images
import product5 from "../../assets/products/Climatiseur 9000BTU-INVERTER R410 MARQUE AIRWELL.png";
import product6 from "../../assets/products/Climatiseur 12000BTU- ON OFF R410 MARQUE AIRWELL.png";
import product7 from "../../assets/products/Climatiseur 9000BTU-VERTU-ON OFF R22 MARQUE CARRIER.png";
import product8 from "../../assets/products/Climatiseur 12000BTU-ON OFF R22 MARQUE SUMSUNG.png";

import image1 from "../../assets/products/MBB PERC MONOFACIAL MODULE 500W-500W MARQUE IFRISOL.png";
import image2 from "../../assets/products/MBB PERC MONOFACIAL MODULE 395W MARQUE IFRISOL.png";
import image3 from "../../assets/products/MBB PERC MONOFACIAL MODULE 335W MARQUE IFRISOL.png";
import image4 from "../../assets/products/MBB PERC MONOFACIAL MODULE 270W MARQUE RESUN.png";

export const Shop = ({ selectedCategory, searchQuery }) => {
  // Filter products based on the selected category and search query
  const filteredProducts = PRODUCTS.filter((product) => {
    const categoryMatch = !selectedCategory || product.category.toLowerCase() === selectedCategory.toLowerCase();
    const searchMatch =
      !searchQuery ||
      product.productName.toLowerCase().includes(searchQuery.toLowerCase());
    return categoryMatch && searchMatch;
  });

  // Settings for the slider
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000
  };

  return (
    <div className="shop">
      <h1 className="shopTitle"><br/><br/></h1>
      <div className="slider-container">
        <Slider {...sliderSettings}>
          <div>
            <div className="image-container">
              <img src={product5} alt="Climatiseur 9000BTU-INVERTER R410" className="product-image" />
            </div>
          </div>
          <div>
            <div className="image-container">
              <img src={product6} alt="Climatiseur 12000BTU-ON OFF R410" className="product-image" />
            </div>
          </div>
          <div>
            <div className="image-container">
              <img src={product7} alt="Climatiseur 9000BTU-VERTU-ON OFF R22" className="product-image" />
            </div>
          </div>
          <div>
            <div className="image-container">
              <img src={product8} alt="Climatiseur 12000BTU-ON OFF R22" className="product-image" />
            </div>
          </div>
          <div>
            <div className="image-container">
              <img src={image1} alt="Image 1" className="product-image" />
            </div>
          </div>
          <div>
            <div className="image-container">
              <img src={image2} alt="Image 2" className="product-image" />
            </div>
          </div>
          <div>
            <div className="image-container">
              <img src={image3} alt="Image 3" className="product-image" />
            </div>
          </div>
          <div>
            <div className="image-container">
              <img src={image4} alt="Image 4" className="product-image" />
            </div>
          </div>
        </Slider>
      </div>
      <div className="products">
       
        {/* Render other products */}
        {filteredProducts.map((product) => (
          <Product key={product.id} data={product} />
        ))}
      </div>
    </div>
  );
};
