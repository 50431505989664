import React from 'react';
import { FaEnvelope, FaPhone, FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';
import './Footer.css';

const Footer = () => {
  const email = 'wavedestibution@test2.electricwave.ma';
  const phoneNumber = '0667780980 /  0524446007';
  const description = 'Free shipping in Marrakech';

  return (
  <div class="content">
    <footer>
      <div className="contact-info">
        <div className="contact-item">
          <FaEnvelope style={{ color: 'white', marginRight: '5px' }} />
          <a href={`mailto:${email}`} className="contact-link" style={{ color: 'white' }}>{email}</a>
        </div><br>
        </br>
        <div className="contact-item">
          <FaPhone style={{ color: '#3498DB', marginRight: '5px' }} />
          <span className="contact-link">{phoneNumber}</span>
        </div>
      </div>
      <div className="footer-content">
        <div className="description">
          <p>{description}</p>
        </div>
        <div className="social-media">
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
            <FaFacebook style={{ color: '#3b5998', marginRight: '15px', fontSize: '20px' }} />
          </a>
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
            <FaInstagram style={{ color: '#C13584', marginRight: '15px', fontSize: '20px' }} />
          </a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
            <FaTwitter style={{ color: '#1DA1F2', marginRight: '15px', fontSize: '20px' }} />
          </a>
        </div>
      </div>
    </footer>
  </div>
  );
};

export default Footer;
