import React, { useContext } from "react"; // Remove this duplicate import
import { ShopContext } from "../../context/shop-context";
import "./product.css"; // Import CSS for product styling

export const Product = (props) => {
  const { id, productName, price, productImage, description } = props.data; // Added description to destructuring
  const { addToCart, cartItems } = useContext(ShopContext);

  const cartItemCount = cartItems[id];

  return (
    <div className="product">
       <title>Product Details - {productName}</title>
      <div className="image-container">
        <div className="image-wrapper">
          <img
            src={productImage}
            alt={productName}
            className="product-image"
          />
        </div>
      </div>
      <div className="description">
        <p>
          <b><h3>{productName}</h3></b>
        </p> 
        <p>
          <b>{description}</b> {/* Display the description */}
        </p>
        <p>{price}</p>
      </div>
      <button
        className="addToCartButton" // Updated class name for the button
        onClick={() => addToCart(id)}
      >
        Add To Cart {cartItemCount > 0 && <> ({cartItemCount})</>}
      </button>
    </div>
  );
};
