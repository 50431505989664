import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navbar } from "./components/navbar";
import { Shop } from "./pages/shop/shop";
import { Contact } from "./pages/contact/Contact";
import { Cart } from "./pages/cart/cart";
import { ShopContextProvider } from "./context/shop-context";
import Footer from "./pages/footer/Footer"; // Import the Footer component
import SocialNavbar from "./components/socialNavbar"; // Import the SocialNavbar component

function App() {
  const categories = ["Panneau Photovoltaique", "Climatisation"];
  const [selectedCategory, setSelectedCategory] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  return (
    <div>
      <ShopContextProvider>
        <Router>
          <SocialNavbar /> {/* Call the SocialNavbar component here */}
          <Navbar categories={categories} onCategoryChange={handleCategoryChange} onSearch={handleSearch} />
          <Routes>
            <Route
              path="/"
              element={<Shop selectedCategory={selectedCategory} searchQuery={searchQuery} />}
            />
            <Route path="/cart" element={<Cart />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
          <Footer /> {/* Call the Footer component here */}
        </Router>
      </ShopContextProvider>
    </div>
  );
}

export default App;
