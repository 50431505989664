import React from "react";
import { FaPhone, FaWhatsapp, FaInstagram, FaFacebook, FaEnvelope } from "react-icons/fa";
import "./socialNavbar.css"; // You can create a separate CSS file for styling social navbar

const SocialNavbar = () => {
  return (
    <nav className="social-navbar">
      <div className="left-content">
        <span className="email">
          <FaEnvelope />
        </span>
        <span>
          <a href="mailto:myelectricashop@gmail.com" className="email-address">
            myelectricashop@gmail.com
          </a>
        </span>
      </div>
      <div className="social-icons">
        <a href="tel:1234567890" className="social-icon">
          <FaPhone />
        </a>
        <a href="https://wa.me/1234567890" className="social-icon">
          <FaWhatsapp />
        </a>
        <a href="https://www.instagram.com/" className="social-icon">
          <FaInstagram />
        </a>
        <a href="https://www.facebook.com/profile.php?id=61558139135108" className="social-icon">
          <FaFacebook />
        </a>
      </div>
    </nav>
  );
};

export default SocialNavbar;
