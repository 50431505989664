import React, { createContext, useEffect, useState } from "react";
import { PRODUCTS } from "../products";

export const ShopContext = createContext(null);

const getDefaultCart = () => {
  let cart = {};
  for (let i = 1; i < PRODUCTS.length + 1; i++) {
    cart[i] = 0;
  }
  return cart;
};

export const ShopContextProvider = (props) => {
  const [cartItems, setCartItems] = useState(getDefaultCart());
  const [cartItemsCount, setCartItemsCount] = useState(0);

  // Calculate the total count of items in the cart
  useEffect(() => {
    let totalCount = Object.values(cartItems).reduce((acc, count) => acc + count, 0);
    setCartItemsCount(totalCount);
  }, [cartItems]);

  const addToCart = (itemId) => {
    setCartItems((prev) => ({
      ...prev,
      [itemId]: prev[itemId] + 1,
    }));
  };

  const removeFromCart = (itemId) => {
    setCartItems((prev) => ({
      ...prev,
      [itemId]: prev[itemId] - 1 >= 0 ? prev[itemId] - 1 : 0,
    }));
  };

  const updateCartItemCount = (newAmount, itemId) => {
    setCartItems((prev) => ({
      ...prev,
      [itemId]: newAmount >= 0 ? newAmount : 0,
    }));
  };

  const checkout = () => {
    setCartItems(getDefaultCart());
  };

  // Calculate the total cart amount
  const getTotalCartAmount = () => {
    let totalAmount = 0;
    for (const item in cartItems) {
      if (cartItems[item] > 0) {
        let itemInfo = PRODUCTS.find((product) => product.id === Number(item));
        totalAmount += cartItems[item] * itemInfo.price;
      }
    }
    return totalAmount;
  };

  const contextValue = {
    cartItems,
    cartItemsCount,
    addToCart,
    updateCartItemCount,
    removeFromCart,
    checkout,
    getTotalCartAmount, // Add getTotalCartAmount to context
  };

  return <ShopContext.Provider value={contextValue}>{props.children}</ShopContext.Provider>;
};
