import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { ShoppingCart } from "phosphor-react";
import { MdShop } from "react-icons/md";
import { FaSearch, FaTimes, FaBars } from 'react-icons/fa'; 
import "./navbar.css";
import logo from "../images/logo.png";
import { ShopContext } from "../context/shop-context";

export const Navbar = ({ categories, onCategoryChange, onSearch }) => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();
  const { cartItemsCount } = useContext(ShopContext);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    onCategoryChange(category);
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    onSearch(query);
  };

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.closest(".navbar") === null) {
        setIsSidebarOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <nav className="navbar">  <title>Electric Appliances Shop - climatiseur installation</title>
    <div className="header-menu-and-logo">
      <div className="menu-header">
        <div className="btn-container">
          <div onClick={toggleSidebar} className="button-simple nav-menu-header-btn" id="nav-menu-header-btn">
            <FaBars className="menu-icon" />
          </div>
        </div>
        <label className="labelmeny">MENU</label>
      </div>
      <div className="brand">
        <img src={logo} alt="Logo" className="logo" />
        <Link to="/" className="site-name">
          My Electrica Shop
        </Link>
      </div>
    </div>
    <div className="search-input-container">
      <div className={`search-icon ${isSearchOpen ? 'open' : ''}`} onClick={toggleSearch}>
        <FaSearch />
      </div>
      <input
        type="text"
        placeholder="Rechercher...."
        value={searchQuery}
        onChange={handleSearch}
        className={`search-input ${isSearchOpen ? 'open' : ''}`}
      />
    </div>
    <div className="links">
      <Link to="/" className="nav-link right-link">
        <MdShop size={24}/>
        Shop
      </Link>
      <Link to="/cart" className="nav-link right-link">
        <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
          <ShoppingCart size={24} />
          {cartItemsCount > 0 && (
            <span style={{ marginLeft: "5px", color: "white", position: "absolute", top: "-10px", right: "-10px", backgroundColor: "red", borderRadius: "50%", padding: "2px 5px" }}>{cartItemsCount}</span>
          )}
          Cart
        </div>
      </Link>
    </div>
    <div></div>
    <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`} style={{ width: "300px" }}>
      <div className="close-icon" onClick={closeSidebar}>
        <FaTimes />
      </div>
      <ul className="category-list">
        {categories.map((category, index) => (
          <li key={index} className={`category ${selectedCategory === category ? 'active' : ''}`} onClick={() => handleCategoryChange(category)}>
            <span>{category}</span>
          </li>
        ))}
      </ul>
    </div>
  </nav>
  
  );
};
