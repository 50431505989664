import React, { useContext, useState, useEffect } from "react";
import { ShopContext } from "../../context/shop-context";
import { PRODUCTS } from "../../products";
import { useNavigate } from "react-router-dom";
import "./cart.css";
import Contact from "../contact/Contact";

export const Cart = () => {
  const { cartItems, getTotalCartAmount, removeFromCart, updateCartItemCount } = useContext(
    ShopContext
  );
  const totalAmount = getTotalCartAmount();
  const navigate = useNavigate();

  // Function to remove product from cart
  const removeProductFromCart = (itemId) => {
    updateCartItemCount(0, itemId);
    removeFromCart(itemId);
  };

  // Function to handle quantity change
  const handleQuantityChange = (itemId, quantity) => {
    updateCartItemCount(quantity, itemId);
  };

  // Function to render the table rows for cart items
  // const renderCartItems = () => {
  //   return Object.entries(cartItems || {}).map(([itemId, quantity]) => {
  //     const product = PRODUCTS.find((p) => p.id === Number(itemId));
  //     if (product && quantity > 0) {
  //       return (
  //         <tr key={itemId}>
  //           <td>
  //             <img
  //               src={product.productImage}
  //               alt={product.productName}
  //               style={{ width: "100px", height: "100px" }}
  //             />
  //           </td>
  //           <td>{product.productName}</td>
  //           <td>
  //             <input
  //               type="number"
  //               min="1"
  //               value={quantity}
  //               onChange={(e) =>
  //                 handleQuantityChange(itemId, parseInt(e.target.value))
  //               }
  //             />
  //           </td>
  //           <td>{product.price}DH</td>
  //           <td>{product.price * quantity}DH</td>
           
  //         </tr>
  //       );
  //     }
  //     return null;
  //   });
  // };

  return (
    <div className="cart-container-wrapper">
      <div className="cart-container">
        <div className="cart-products">
          {/* <table className="product-table">
            <thead>
              <tr>
                <th>Images</th>
                <th>Produit</th>
                <th>Quantité</th>
                <th>Prix unitaire</th>
                <th>Prix total</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{renderCartItems()}</tbody>
          </table> */}
        </div>
      </div>
      <Contact cartItems={cartItems} />
      {totalAmount > 0 && (
        <div className="checkout">
          {/* <button onClick={() => navigate("/")}>Revenir aux achats</button> */}
        </div>
      )}
    </div>
  );
};

export default Cart;